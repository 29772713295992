import { useRouter } from 'next/router';
import React, { ReactNode, useEffect, useState } from 'react';
import Modal from '@components/utilities/Modal/Modal';

type ModalSize = 'medium' | 'large';

interface Props {
  children: ReactNode;
}

interface ModalOptions {
  title?: string;
  id: string;
  closeOnRouteChange?: boolean;
  size?: ModalSize;
}

interface ModalProps {
  content: ReactNode | null;
  options: ModalOptions;
}

interface ModalProviderType {
  setModal: (prop: ModalProps | null) => void;
  modal: ModalProps | null;
}

export const ModalContext = React.createContext<ModalProviderType>({
  modal: null,
  setModal: () => {},
});

const ModalProvider = ({ children }: Props) => {
  const [modal, setModalState] = useState<ModalProps | null>(null);
  const router = useRouter();

  useEffect(() => {
    const onRouteChangeComplete = () => {
      if (modal?.options.closeOnRouteChange) {
        setModalState(null);
      }
    };

    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [modal, router.events]);

  const setModal = (props: ModalProps | null) => {
    if (!props) {
      setModalState(null);
    } else {
      setModalState({
        content: props.content || null,
        options: {
          title: props.options.title,
          id: props.options.id,
          closeOnRouteChange: props.options.closeOnRouteChange ?? true,
          size: props.options.size,
        },
      });
    }
  };

  const context = {
    modal,
    setModal,
  };

  return (
    <ModalContext.Provider value={context}>
      {children}
      {modal && <Modal />}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
